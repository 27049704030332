<template>
  <div>
    <div class="all-apartments">
      <AdminTable
        title="Apartments Pricing"
        :list="apartmentList"
        :fields="fields"
        :loading="loading"
        @edit="editApartment"
      />
    </div>
    <b-modal hide-footer title="Set prices for apartment" v-model="showPricingModal">
      <ValidationObserver ref="validator">
        <form class="edit-apartment-form" @submit.prevent>
          <b-row>
            <b-col>
              <RFValidation rules="required" name="Monthly Amount">
                <RFCurrencyInput label="Monthly Amount" v-model="apartment.monthlyAmount"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation rules="required" name="Deposit Amount">
                <RFCurrencyInput label="Deposit Amount" v-model="apartment.depositAmount"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation rules="required" name="Service Fees">
                <RFCurrencyInput label="Service Fees" v-model="apartment.serviceFees"/>
              </RFValidation>
            </b-col>
          </b-row>

          <b-row class="justify-content-end mb-5">
            <b-col cols="auto">
              <RFButton class="mt-3" size="small" label="Save" background="#5CB86C" v-if="!loading" @on-click="save"/>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import RFButton from '@/components/forms/RFButton'
import {apartmentService} from '@/services/admin'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFValidation from '@/components/forms/RFValidation'

export default {
  components: {
    AdminTable,
    RFValidation,
    RFButton,
    RFCurrencyInput
  },
  data() {
    return {
      apartmentList: [],
      loading: false,
      showPricingModal: false,
      apartment: {}
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Apartment' },
        { key: 'numOfPerson', label: 'Num Person'},
        { key: 'floor', label: 'Floor' },
        { key: 'monthlyAmount', label: 'Monthly Amount', type: 'cash'},
        { key: 'depositAmount', label: 'Deposit Amount', type: 'cash'},
        { key: 'serviceFees', label: 'Service Fees', type: 'cash'},
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit'] },
      ]
    },
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.apartmentList = await apartmentService.getApartments()
      this.showPricingModal = false
      this.loading = false
    },
    editApartment(apartment) {
      Object.assign(this.apartment, apartment)
      this.showPricingModal = true
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        const response = await apartmentService.editApartment(this.apartment)

        if (response.status === 200) {
          this.showPricingModal = false
          await this.update()
          this.$toasted.success('Prices updated!')
        } else {
          this.$toasted.error(this.$i18n.t(`Error during edit apartment`))
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
b-modal {
  height: auto !important;
}
</style>
